import { Domains, LinkTarget } from '@constants_folder/common'

const HEADING_REGEX = /(<h2>|<h3>)((?!(<\/h2>|<\/h3>)).)*(<\/h2>|<\/h3>)/gi
const HEADING_2_REGEX = /<h2((?!(<\/h2>)).)*<\/h2>/gi
const IMAGE_REGEX = /<img /gi
const LINK_REGEX = /<a\s+(.*?)href="(.*?)"(.*?)>/gi
const START_HEADING_POSITION = 4
const END_HEADING_POSITION = -5
const DOFOLLOW_LINKS = ['https://ling-app.com/learn-cantonese/']

const clearString = (string: string) =>
  string
    .slice(START_HEADING_POSITION, END_HEADING_POSITION)
    .replaceAll(/<((?!>).)*>|&nbsp;/gi, '')

const setLinksRelAttribute = (
  html: string,
  doFollowLinksFromStrapi?: string[]
): string => {
  const anchorRegex = /<a([^>]*)>/g
  const hrefRegex = /href\s*=\s*["']([^"']*)["']/
  const domainRegex = /promova\.com/
  const slashStartRegex = /^\/.*/

  return html.replace(anchorRegex, (match, attributes: string) => {
    let attribs = attributes

    if (!attribs.match(hrefRegex)) {
      return match
    }

    const hrefValue = hrefRegex.exec(attribs)?.[1]

    if (
      hrefValue &&
      !hrefValue.match(domainRegex) &&
      !hrefValue.match(slashStartRegex)
    ) {
      if (
        DOFOLLOW_LINKS.includes(hrefValue) ||
        doFollowLinksFromStrapi?.includes(hrefValue)
      ) {
        attribs += ' rel="dofollow"'
      } else if (attribs.match(/rel\s*=\s*["'][^"']*["']/)) {
        attribs = attribs.replace(
          /rel\s*=\s*(["'][^"']*)["']/,
          'rel=$1 nofollow"'
        )
      } else {
        attribs += ' rel="nofollow"'
      }
    }

    return `<a${attribs}>`
  })
}

export const createSlug = (string: string) =>
  string.toLowerCase().replaceAll(' ', '_').replaceAll(/\W/gi, '')

export const createContentsArray = (content: string) => {
  const headingMatches = Array.from(content?.matchAll(HEADING_REGEX))
  return headingMatches.map((item) => ({
    value: clearString(item[0]),
    type: item[1],
    index: item.index,
  }))
}

// function for replacing headings with anchor tags
export const headingReplacer = (content: string): string =>
  content.replaceAll(
    HEADING_REGEX,
    (match): string => `<a id="${createSlug(clearString(match))}"></a>${match}`
  )

// function for replacing images with lazy loading images
export const imageReplacer = (content: string): string =>
  content.replaceAll(IMAGE_REGEX, (match): string => `${match} loading="lazy"`)

// function for replacing links with rel attribute
export const linksReplacer = (
  content: string,
  doFollowLinksFromStrapi?: string[]
): string => setLinksRelAttribute(content, doFollowLinksFromStrapi)

// function for replacing external links with target="_blank" and rel="noopener noreferrer"
export const externalLinksReplacer = (content: string): string =>
  content.replaceAll(LINK_REGEX, (match, beforeHref, href, afterHref) => {
    const isInternalLink =
      href.includes(Domains.PRODUCTION) ||
      href.includes(Domains.DEVELOPMENT) ||
      href.includes(Domains.PREVIEW) ||
      href.startsWith('/')

    if (isInternalLink) {
      return match
    }

    return `<a ${beforeHref} href="${href}"${afterHref} target="${LinkTarget.BLANK}" rel="noopener noreferrer">`
  })

export const contentAttributesReplacer = (
  content: string,
  doFollowLinksFromStrapi?: string[]
): string =>
  [externalLinksReplacer, linksReplacer, headingReplacer, imageReplacer].reduce(
    (result, replacer) => replacer(result, doFollowLinksFromStrapi),
    content
  )

export const splitContentBeforeHeading2 = (content: string) => {
  const heading2Matches = Array.from(content?.matchAll(HEADING_2_REGEX))
  const anchorId = createSlug(clearString(heading2Matches?.[1]?.[0] || ''))
  const anchorRegex = new RegExp(`<a[^>]*id="${anchorId}"[^>]*><\\/a>`, 'gi')

  const [beforeAnchor, afterAnchor] = content.split(anchorRegex)

  return {
    firstPart: beforeAnchor,
    secondPart: (content.match(anchorRegex)?.[0] || '') + afterAnchor,
  }
}
