import classNames from 'classnames'

import { useMountedCtaContext } from '@modules/CTA/MountedCtaContext'

import heroHeightStyles from '../../components/templates/Landing/layoutV2/HeroSection/shared_height_constraints.module.scss'

const useHeroSectionHeightClassNames = () => {
  const mountedCtaContext = useMountedCtaContext()
  const is100PxHeightCtaMounted = !!mountedCtaContext?.ctaWithTimer
  const is40PxHeightCtaMounted = !!mountedCtaContext?.ctaWithRunningText

  return classNames(heroHeightStyles.section, {
    [heroHeightStyles.hero_section_cta_100_px]: is100PxHeightCtaMounted,
    [heroHeightStyles.hero_section_cta_40_px]: is40PxHeightCtaMounted,
    [heroHeightStyles.hero_section_cta_140_px]:
      is100PxHeightCtaMounted && is40PxHeightCtaMounted,
  })
}

export default useHeroSectionHeightClassNames
