import React, { FC } from 'react'

import { SeoTypes } from '@_types/seo'

import Seo from '@modules/Seo/Seo'

type Props = {
  seo?: SeoTypes
  hostname?: string
  isBlogArticle?: boolean
  structuredData?: Record<string, any>
}

const SeoLayout: FC<Props> = ({
  children,
  seo,
  isBlogArticle,
  structuredData,
  hostname,
}) => (
  <>
    <Seo seo={seo} isBlogArticle={isBlogArticle} hostname={hostname} />
    {structuredData && (
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(structuredData) }}
      />
    )}
    {children}
  </>
)

export default SeoLayout
