import classNames from 'classnames'
import { ImageProps } from 'next/image'
import { FC, ReactElement } from 'react'

import { IconName } from '@promova/ui/components/Icon/Icon'

import { ButtonProps, SectionProps } from '@_types/landings'

import useHeroSectionHeightClassNames from '@hooks/useHeroSectionHeightClassNames'

import { FontParamsTypes } from '@utils/strapiCommons/types'

import HeroDescriptionBlock from '@templates/Landing/layoutV2/HeroDescriptionBlock/HeroDescriptionBlock'

import Button from '@elements/ButtonV2/Button'
import StrapiImage from '@elements/StrapiImage/StrapiImage'

import styles from './hero_section.module.scss'

interface SectionButton extends ButtonProps {
  column?: 'left' | 'right'
  iconName?: IconName
}

type Props = {
  position?: string
  title: string
  image?: Record<string, any>
  description: {
    hidden?: string
    showed: string
    element?: JSX.Element
  }
  fit?: ImageProps['objectFit']
  button?: SectionButton
  wrappersClassNames?: {
    left?: string
    right?: string
    button?: string
    section?: string
    rightColumn?: string
    leftColumn?: string
  }
  renderRightColumnContent?: () => JSX.Element
  customLabels?: {
    readMore?: string | ReactElement
    showLess?: string | ReactElement
  }
  titleFontParams?: FontParamsTypes
} & SectionProps

const HeroSection: FC<Props> = (props) => {
  const {
    title,
    image,
    description,
    button,
    event,
    wrappersClassNames,
    renderRightColumnContent,
    fit = 'contain',
    position,
    customLabels,
    order,
    disabled,
    titleFontParams,
  } = props

  const sectionHeightClasses = useHeroSectionHeightClassNames()
  if (disabled) return null

  const isButtonInLeftColumn = button?.column === 'left'

  const mobileBtnWrapperClassName = classNames(
    styles.button_wrapper_mobile,
    wrappersClassNames?.button,
    {
      [styles.second_order]: isButtonInLeftColumn,
    }
  )

  const sectionClassNames = classNames(
    wrappersClassNames?.section,
    sectionHeightClasses
  )
  return (
    <section
      className={sectionClassNames}
      data-place={event?.place}
      ref={event?.ref}
      style={{ order }}
    >
      <div
        className={`${styles.left_column} ${wrappersClassNames?.leftColumn}`}
      >
        <HeroDescriptionBlock
          title={title}
          description={{
            firstText: description?.showed,
            secondText: description?.hidden,
          }}
          className={wrappersClassNames?.left}
          customLabels={customLabels}
          titleFontParams={titleFontParams}
        />
        {button && isButtonInLeftColumn && (
          <div
            className={`${styles.button_wrapper} ${wrappersClassNames?.button}`}
          >
            <Button className={styles.button} {...button} scroll={false}>
              {button?.text}
            </Button>
          </div>
        )}
      </div>
      {button && (
        <div className={mobileBtnWrapperClassName}>
          <Button {...button} withScaling={false}>
            {button?.text}
          </Button>
        </div>
      )}

      <div
        className={`${styles.right_column} ${wrappersClassNames?.rightColumn}`}
      >
        {renderRightColumnContent && renderRightColumnContent()}
        {image && !renderRightColumnContent && (
          <div
            className={`${styles.image_wrapper} ${wrappersClassNames?.right}`}
          >
            <figure className={styles.image}>
              <StrapiImage
                priority
                image={image}
                layout="fill"
                fit={fit}
                position={position}
              />
            </figure>
          </div>
        )}

        {button && !isButtonInLeftColumn && (
          <div
            className={`${styles.button_wrapper} ${wrappersClassNames?.button}`}
          >
            <Button className={styles.button} {...button}>
              {button.text}
            </Button>
          </div>
        )}
      </div>
    </section>
  )
}

export default HeroSection
