import sortByOrder from '@promova/utils/sortByOrder'

import { FaqBlock } from '@_types/articles'
import { FaqQuestion } from '@_types/faq'
import { FAQBlock, KeyValue } from '@_types/landings'

export default function createFaqArray(
  sourceArray: FAQBlock | FaqBlock,
  isFaqExtended = false
) {
  const result: FaqQuestion[] = []

  if (sourceArray) {
    sortByOrder(sourceArray.faq).forEach((item) => {
      result.push({
        id: item.id,
        title: item.question,
        content: `${isFaqExtended ? item.answer : <p>{item.answer}</p>}`,
      })
    })
  }

  return result
}

export function createFaqArrayFromKeyValue(sourceArray: KeyValue[]) {
  const result: FaqQuestion[] = []

  if (sourceArray) {
    sortByOrder(sourceArray).forEach((item) => {
      result.push({
        id: item.id,
        title: item.key,
        content: item.value,
      })
    })
  }

  return result
}
