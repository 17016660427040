import { Accordion } from '@boosters/wb-ui-kit'
import { CSSProperties, FC, memo } from 'react'

import { FaqQuestion } from '@_types/faq'

import { contentAttributesReplacer } from '@templates/Articles/helpers'

import styles from './faq.module.scss'

type Variants = 'default' | 'line'
export type StylesType = {
  container?: CSSProperties
  item?: CSSProperties
  title?: CSSProperties
}

interface Props {
  questions: FaqQuestion[]
  variant?: Variants
  questionTag?: string
  isAnswerExtended?: boolean
  customStyles?: StylesType
  answerClassName?: string
  enableNumeration?: boolean
  isMultiSelected?: boolean
  onClickItem?: (text?: string) => void
}

const defaultStyles: StylesType = {
  container: {},
  item: { boxShadow: '0 4px 40px rgb(0 0 0 / 8%)' },
}
const lineStyles: StylesType = {
  container: { gap: 0, borderBottom: '1px solid #000' },
  item: {
    borderRadius: 0,
    background: 'none',
    borderTop: '1px solid #000',
  },
}
const variantStyles: Record<Variants, StylesType> = {
  default: defaultStyles,
  line: lineStyles,
}
const FAQ: FC<Props> = ({
  questions,
  variant = 'default',
  questionTag = 'span',
  isAnswerExtended = false,
  customStyles,
  answerClassName = '',
  enableNumeration,
  isMultiSelected = true,
  onClickItem,
}) => {
  if (!questions) return null

  const currentStyles = variantStyles[variant]

  const renderItems = () =>
    questions.map(({ title, content, id }, index) => {
      const replaceContent = contentAttributesReplacer(String(content))

      return (
        <Accordion.Item
          key={title}
          id={`${id || title}`}
          title={enableNumeration ? `${index + 1}. ${title}` : title}
          titleStyles={customStyles?.title}
          styles={{ ...currentStyles.item, ...customStyles?.item }}
          questionTag={questionTag}
          onClick={onClickItem}
        >
          <Accordion.Item.Body>
            {isAnswerExtended ? (
              <div
                className={`${styles.answer} ${answerClassName}`}
                dangerouslySetInnerHTML={{ __html: replaceContent as string }}
              />
            ) : (
              content
            )}
          </Accordion.Item.Body>
        </Accordion.Item>
      )
    })

  return (
    <div className={styles.container}>
      <Accordion
        multiSelect={isMultiSelected}
        styles={{ ...currentStyles.container, ...customStyles?.container }}
      >
        {renderItems()}
      </Accordion>
    </div>
  )
}
export default memo(FAQ)
