import { FC, ReactNode } from 'react'
import styles from './errorLayout.module.scss'

interface Props {
  isError: boolean
  children: ReactNode
}

const ErrorLayout: FC<Props> = ({ isError, children }: Props) => (
  // eslint-disable-next-line react/jsx-no-useless-fragment
  <>
    {isError ? (
      <div className={styles.wrapper}>
        <span className={styles.text}>Something went wrong.</span>
        <span className={styles.text}>Please try again later.</span>
      </div>
    ) : (
      children
    )}
  </>
)

export default ErrorLayout
